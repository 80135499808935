import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import type { CoreGraphqlEntryProps } from "@/types/page";
import { useCustomModuleCarouselQuery } from "@/components/contentful/module-carousel/custom-query";
import { ModuleCarousel } from "./module-carousel";

export type ModuleCarouselGraphqlProps = CoreGraphqlEntryProps & {
    isEmbedded?: boolean;
};

export const ModuleCarouselGraphql = ({
    id,
    locale,
    preview,
    isEmbedded,
    index,
}: ModuleCarouselGraphqlProps) => {
    const { data, isLoading } = useCustomModuleCarouselQuery({ id, locale, preview });

    const moduleCarousel = useContentfulLiveUpdates(data?.moduleCarousel);
    const microcopySet = data?.microcopySetCollection?.items[0];

    if (isLoading || !moduleCarousel || !microcopySet) {
        return null;
    }

    return (
        <ModuleCarousel
            {...moduleCarousel}
            isEmbedded={isEmbedded}
            microcopySet={microcopySet}
            moduleIndex={index}
        />
    );
};
